import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import Flickity from 'react-flickity-component'
import AOS from 'aos'
import Layout from "../layouts"
import { Helmet } from "react-helmet"

function myFunction() {
  var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
  var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  var scrolled = (winScroll / height) * 100;
  document.getElementById("progress-bar").style.width = scrolled + "%";
}

function colorizeHero(html, color){
  console.log(html);
  html = html.replace('<b>', '<b style="color: ' + color + '">')
  return(
    '<div>' + html + '</div>'
  )
}

function colorizeText(html, color){
  return(
    '<div style="color:' + color + '">' + html + '</div>'
  )
}

function getRelativeURL(x){
  if (x !== undefined){
    return x.replace(/^.*\/\/[^\/]+/, '')
  }
}

function checkAnimation(i, animatedIn){
  if(i === 0 && animatedIn === false){
    return 0
  }else{
    return 1
  }
}

function checkAnimationDelay(i, animatedIn){
  if(i === 0 && animatedIn === false){
    return 0
  }else if(i === 0 && animatedIn === true){
    return 1;
  }else{
    return 1
  }
}

function colorizeFlag(html, color){

  let svg = '<div class="flag w" data-aos="draw-line"><svg width="125px" height="10px" viewBox="0 0 125 10"><g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="About---Desktop" transform="translate(-140.000000, -1460.000000)" fill="#FFFFFF"><g id="Group" transform="translate(140.000000, 1460.000000)"><polygon id="Rectangle-Copy-6" points="0 0 120.192308 0 125 5 120.192308 10 0 10 4.20673077 5"></polygon></g></g></g></svg><div className="line"></div></div>'
  html = html + " " + svg
  html = html.replace('<polygon', '<polygon fill="' + color + '"');
  return(
    '<div>' + html + '</div>'
  )
}

function colorizeLargeText(html, color){
  return(
    '<div class="bleeding-brand" style="background-color:' + color + '">' + html + '</div>'
  )
}

class WorkTemplate extends Component {
  
  componentDidMount() {
    AOS.init();
    window.onscroll = function() {
      myFunction();
      AOS.refresh();
    };
    window.onload = function() {
      AOS.refresh();
    };
  }

  componentDidUpdate(){
   console.log('hi')
  }

  render() {
    const post = this.props.data.wordpressWpWork

    let lastItemInArray = this.props.data.allWordpressWpWork.nodes[this.props.data.allWordpressWpWork.nodes.length - 1].menu_order
    let firstItemInArray = this.props.data.allWordpressWpWork.nodes[0].menu_order
    let max = lastItemInArray - firstItemInArray
    let indexOfCurrentWorkItem = this.props.data.allWordpressWpWork.nodes.findIndex(function(node) {
      return node.menu_order == post.menu_order
    });

    let filteredDataForNextItem;
    // const lengthOfAllWork = this.props.data.allWordpressWpWork.nodes.length

    let filteredDataIndex = 0;
    console.log("FIRSTMENUORDER")
    console.log(indexOfCurrentWorkItem)

    if(indexOfCurrentWorkItem == max){
      filteredDataIndex = 0;
      filteredDataForNextItem = this.props.data.allWordpressWpWork.nodes[filteredDataIndex]
    }else{
      filteredDataIndex = indexOfCurrentWorkItem+1
      console.log(filteredDataIndex)
      filteredDataForNextItem = this.props.data.allWordpressWpWork.nodes[filteredDataIndex]
    }

    let acfColor = '#F3A738';

    if (typeof window === 'undefined'){
      return (
        <Layout>
        <Helmet script={[{
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
              "@context": "http://schema.org",
              "@type": "WebPage",
              "url": "https://excelinstore.com/",
              "name": "Excel | Moving Retail Forward",
              "author": {
                  "@type": "Organization",
                  "name": "Excel",
                  "url": "https://excelinstore.com/",
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "1021 International Drive",
                      "addressLocality": "Fergus Falls",
                      "addressRegion": "MN",
                      "postalCode": "56537",
                      "addressCountry": "USA"
                  },
                  "contactPoint": {
                      "@type": "ContactPoint",
                      "contactType": "customer support",
                      "telephone": "+1-218-998-3800",
                      "email": "contact@excelinstore.com"
                  }
              },
              "description": "We guide clients through the creation of in-store visual merchandising displays and fixtures.",
              "publisher": {
                  "@type": "Organization",
                  "name": "Excel",
                  "url": "https://excelinstore.com/",
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "1021 International Drive",
                      "addressLocality": "Fergus Falls",
                      "addressRegion": "MN",
                      "postalCode": "56537",
                      "addressCountry": "USA"
                  }
              }
            })
          }]}>
          <html lang="en" />
          <meta charSet="utf-8" />
          <title>Excel | {post.title}</title>
          <meta name="description" content={post.acf.meta_description} />
          <meta property="og:title" content={"Excel | " + post.title}  />
          <meta property="og:type" content="WebSite" />
          <meta property="og:url" content="https://excelinstore.com/" />
          <meta property="og:image" content={post.acf.case_study.service_hero_images[0].image.url} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:description" content={post.acf.meta_description} />
          <meta property="og:site_name" content="Excel" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={"Excel | " + post.title} />
          <meta name="twitter:description" content={post.acf.meta_description} />
          <meta name="twitter:image" content={post.acf.case_study.service_hero_images[0].image.url} />
          
        </Helmet>
        </Layout>
        )
    }else{
       window.onload = function() {
        AOS.refresh();
      };
      var called = false
      window.addEventListener('mousedown', function() {
        if (called === false){
            let elem = document.getElementById('excel-global-header-inner')
            elem.classList.add('using-mouse');
            called = true
        }
      });

      return(
        <Layout>
        <Helmet script={[{
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
              "@context": "http://schema.org",
              "@type": "WebPage",
              "url": "https://excelinstore.com/",
              "name": "Excel | Moving Retail Forward",
              "author": {
                  "@type": "Organization",
                  "name": "Excel",
                  "url": "https://excelinstore.com/",
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "1021 International Drive",
                      "addressLocality": "Fergus Falls",
                      "addressRegion": "MN",
                      "postalCode": "56537",
                      "addressCountry": "USA"
                  },
                  "contactPoint": {
                      "@type": "ContactPoint",
                      "contactType": "customer support",
                      "telephone": "+1-218-998-3800",
                      "email": "contact@excelinstore.com"
                  }
              },
              "description": "We guide clients through the creation of in-store visual merchandising displays and fixtures.",
              "publisher": {
                  "@type": "Organization",
                  "name": "Excel",
                  "url": "https://excelinstore.com/",
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "1021 International Drive",
                      "addressLocality": "Fergus Falls",
                      "addressRegion": "MN",
                      "postalCode": "56537",
                      "addressCountry": "USA"
                  }
              }
            })
          }]}>
          <html lang="en" />
          <meta charSet="utf-8" />
          <title>Excel | {post.title}</title>
          <meta name="description" content={post.acf.meta_description} />
          <meta property="og:title" content={"Excel | " + post.title}  />
          <meta property="og:type" content="WebSite" />
          <meta property="og:url" content="https://excelinstore.com/" />
          <meta property="og:image" content={post.acf.case_study.service_hero_images[0].image.url} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:description" content={post.acf.meta_description} />
          <meta property="og:site_name" content="Excel" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={"Excel | " + post.title} />
          <meta name="twitter:description" content={post.acf.meta_description} />
          <meta name="twitter:image" content={post.acf.case_study.service_hero_images[0].image.url} />
          
        </Helmet>
        <div className="progress"><div id="progress-bar" style={{backgroundColor: acfColor}}></div></div>
        <div className="hero--wrapper">
          <div className="hero">
            <h1 className="micro" data-aos="fade-up" data-aos-delay="400" dangerouslySetInnerHTML={{ __html: post.acf.case_study.client_short_name }}></h1>
            <div className="cs-title h2 longer" data-aos="fade-up" data-aos-delay="800" dangerouslySetInnerHTML={{ __html: colorizeHero(post.acf.case_study.detail_page_hero, acfColor) }}/>
          </div>
        </div>
        { post.acf &&
          post.acf.case_study &&
          post.acf.case_study.service_hero_images &&
          post.acf.case_study.service_hero_images.length > 0 && 
          <div className="cs-inner--image detail" style={{'backgroundImage': "url(" + post.acf.case_study.service_hero_images[0].image.url + ")"}} data-aos="fade-up" data-aos-delay="1200" data-aos-anchor=".hero--wrapper">
            <img
              src={post.acf.case_study.service_hero_images[0].image.url}
              alt={post.acf.case_study.service_hero_images[0].image.alt} />
          </div>
        }
        { post.acf &&
          post.acf.case_study &&
          post.acf.case_study.service_hero_images &&
          post.acf.case_study.service_hero_images.length > 1 && 
          <div className="js-cs-inner detail" data-aos="fade-up" data-aos-delay="1200" data-aos-anchor=".hero--wrapper">
          { post.acf.case_study.service_hero_images.map((image, i) => (
            <div className="cs-inner--image" style={{'backgroundImage': image.image.url}}>
              <img
                src={image.image.url}
                alt={image.image.alt} /> 
            </div>
            ))
          }
          </div>
        }
        <div className="case_study_content">
          {post.acf &&
            post.acf.cs_content_work &&
            post.acf.cs_content_work.map((layout, i) => {
              if (layout.__typename === `WordPressAcf_full_width_quote_cs`) {
                return (
                  <div key={`${i} full_width_quote_cs`} data-aos="fade-up" data-aos-delay="300" >
                    <div className="full_width_quote">
                      { layout.full_width_quote_cs &&
                        <div className="">{layout.full_width_quote_cs.quote}</div>
                      }
                      { layout.full_width_quote_cs &&
                        <div className="">{layout.full_width_quote_cs.author}</div>
                      }
                    </div>
                  </div>
                )
              }
              if (layout.__typename === `WordPressAcf_inline_images_cs`) {
                return (
                  <div className="inline_images" key={`${i} inline_images_cs`} data-aos="fade-up" data-aos-delay="300">
                    { layout.images.length > 1 &&
                      <Flickity flickityRef={c => this.flkty = c}>
                       { 
                          layout.images.map((image, i) => {
                            return (
                              <div className="image" key={"image" + i}>
                                <img src={image.image.url} alt={image.image.alt} />
                              </div>
                            )
                          })
                        }
                      </Flickity>
                    }
                    {
                      layout.images.length < 2 &&
                      <div className="image">
                        <img src={layout.images[0].image.url} alt={layout.images[0].image.alt} />
                      </div>
                    }
                  </div>
                )
              }
              if (layout.__typename === `WordPressAcf_full_bleed_images_cs`) {
                return (
                  <div className="full_bleed_images" key={`${i} inline_images_cs`} data-aos="fade-up" data-aos-delay="300">
                    { layout.images.length > 1 &&
                      <Flickity flickityRef={c => this.flkty = c}>
                       { 
                          layout.images.map((image, i) => {
                            return (
                              <div className="image" key={"image" + i}>
                                <img src={image.image.url} alt={image.image.alt} />
                              </div>
                            )
                          })
                        }
                      </Flickity>
                    }
                    {
                      layout.images.length < 2 &&
                      <div className="image">
                        <img src={layout.images[0].image.url} alt={layout.images[0].image.alt} />
                      </div>
                    }
                  </div>
                )
              }
              if (layout.__typename === `WordPressAcf_two_column_cs`) {
                return (
                  <div className="two_columns" key={`${i} two_column_cs`} >
                    <div className="two_column--left" data-aos="fade-up" data-aos-delay="300">
                      { layout.sc_lc &&
                        layout.sc_lc.cs_lc_fc &&
                        layout.sc_lc.cs_lc_fc.map((section, i) => {
                          return(
                            <div key={'section_sc_lc_' + i}>
                            { section.images_cs_lc_repeater !== undefined &&
                              section.images_cs_lc_repeater !== null &&
                                <div key={`${i} images_cs_lc_repeater`} >
                                  { section.images_cs_lc_repeater.length > 1 &&
                                    <Flickity flickityRef={c => this.flkty = c}>
                                     { 
                                        section.images_cs_lc_repeater.map((image, i) => {
                                          return (
                                            <div className="image" key={"image" + i}>
                                              <img src={image.image_cs_lc_ri.url} alt={image.image_cs_lc_ri.alt} />
                                            </div>
                                          )
                                        })
                                      }
                                    </Flickity>
                                  }
                                  { section.images_cs_lc_repeater &&
                                    section.images_cs_lc_repeater.length < 2 &&
                                    <div className="image">
                                      <img src={section.images_cs_lc_repeater[0].image_cs_lc_ri.url} alt={section.images_cs_lc_repeater[0].image_cs_lc_ri.alt} />
                                    </div>
                                  }
                                </div>
                              }
                              { section.quote_cs_lc_text &&
                                section.quote_cs_lc_text !== null &&
                                <div className="quote--wrapper">
                                  <div className="quote--text" dangerouslySetInnerHTML={{ __html: colorizeText(section.quote_cs_lc_text, acfColor) }} />
                                  <div className="quote--author"> { section.author_cs_lc_text } </div> 
                                  <div className="quote--author-role"> { section.title_cs_lc_text } </div> 
                                </div>
                              }
                              { section.flag_text_cs_lc &&
                                section.flag_text_cs_lc !== null &&
                                <div className="flag-text" dangerouslySetInnerHTML={{ __html: colorizeFlag(section.flag_text_cs_lc, acfColor) }} />
                                
                              }
                              { section.large_text_cs_lc &&
                                section.large_text_cs_lc !== null &&
                                <div className="" dangerouslySetInnerHTML={{ __html: colorizeLargeText(section.large_text_cs_lc, acfColor) }} />
                              }
                              { section.large_wysiwyg_cs_lc &&
                                section.large_wysiwyg_cs_lc !== null &&
                                <div className="wysiwyg-large" dangerouslySetInnerHTML={{ __html: section.large_wysiwyg_cs_lc }} />
                              }
                              { section.small_wysiwyg_cs_lc &&
                                section.small_wysiwyg_cs_lc !== null &&
                                <div className="wysiwyg-small" dangerouslySetInnerHTML={{ __html: section.small_wysiwyg_cs_lc }} />
                              }
                            </div>
                          )
                        })

                      }
                    </div>
                    <div className="two_column--right" data-aos="fade-up" data-aos-delay="600">
                      { layout.sc_rc &&
                        layout.sc_rc.cs_rc_fc &&
                        layout.sc_rc.cs_rc_fc.map((section, i) => {
                          return(
                            <div key={'section_sc_rc_' + i}>
                            { section.images_cs_rc_repeater !== undefined &&
                              section.images_cs_rc_repeater !== null &&
                                <div key={`${i} images_cs_rc_repeater`} >
                                  { section.images_cs_rc_repeater.length > 1 &&
                                    <Flickity flickityRef={c => this.flkty = c}>
                                     { 
                                        section.images_cs_rc_repeater.map((image, i) => {
                                          return (
                                            <div className="image" key={"image" + i}>
                                              <img src={image.image_cs_rc_ri.url} alt={image.image_cs_rc_ri.alt} />
                                            </div>
                                          )
                                        })
                                      }
                                    </Flickity>
                                  }
                                  { section.images_cs_rc_repeater &&
                                    section.images_cs_rc_repeater.length < 2 &&
                                    <div className="image">
                                      <img src={section.images_cs_rc_repeater[0].image_cs_rc_ri.url} alt={section.images_cs_rc_repeater[0].image_cs_rc_ri.alt} />
                                    </div>
                                  }
                                </div>
                              }
                              { section.quote_cs_rc_text &&
                                section.quote_cs_rc_text !== null &&
                                <div className="quote--wrapper">
                                  <div className="quote--text" dangerouslySetInnerHTML={{ __html: colorizeText(section.quote_cs_rc_text, acfColor) }} />
                                  <div className="quote--author"> { section.author_cs_rc_text } </div> 
                                  <div className="quote--author-role"> { section.title_cs_rc_text } </div> 
                                </div>
                              }
                              { section.flag_text_cs_rc_i &&
                                section.flag_text_cs_rc_i !== null &&
                                <div className="" dangerouslySetInnerHTML={{ __html: colorizeFlag(section.flag_text_cs_rc_i, acfColor) }} />
                              }
                              { section.large_text_cs_rc_i &&
                                section.large_text_cs_rc_i !== null &&
                                <div className="" dangerouslySetInnerHTML={{ __html: colorizeLargeText(section.large_text_cs_rc_i, acfColor) }} />
                              }
                              { section.large_wysiwyg_cs_rc_i &&
                                section.large_wysiwyg_cs_rc_i !== null &&
                                <div className="wysiwyg-large" dangerouslySetInnerHTML={{ __html: section.large_wysiwyg_cs_rc_i }} />
                              }
                              { section.small_wysiwyg_cs_rc_i &&
                                section.small_wysiwyg_cs_rc_i !== null &&
                                <div className="wysiwyg-small" dangerouslySetInnerHTML={{ __html: section.small_wysiwyg_cs_rc_i }} />
                              }
                            </div>
                          )
                        })

                      }
                    </div>
                  </div>
                )
              }
              
              return null
            })
          }
        </div>
        { this.props.data.allWordpressWpWork !== null &&
          <div>
            { filteredDataForNextItem !== undefined &&
              filteredDataForNextItem.link !== "" &&
              <div>
                <Link to={getRelativeURL(filteredDataForNextItem.link)} className="case_study_link_detail">
                  <div className="next-case-study-block">
                    <div className="next__pre-title">next case study</div>
                    <div className="next__title">{ filteredDataForNextItem.acf.case_study.client_short_name }</div> 
                  </div>
                  
                </Link>
                <Link to={'/work'} className="back-link-study-block">
                  <div className="reverse-cta no-hover"><span><span></span></span>Back to all work</div> 
                </Link>
              </div>
            }  
          </div>
        }
      </Layout>
    )
    }
  }
}

WorkTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default WorkTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressWpWork(id: { eq: $id }) {
      title
      content
      menu_order
      acf {
        meta_description
        case_study{
          client_short_name
          detail_page_hero
          service_hero_images{
            image{
              alt
              url
            }
          }
        }
        cs_content_work {
          ... on WordPressAcf_full_width_quote_cs {
            id
            author
            quote
          }
          ... on WordPressAcf_inline_images_cs {
            id
            images {
              image {
                alt
                url
              }
            }
          }
          ... on WordPressAcf_full_bleed_images_cs {
            id
            images {
              image {
                alt
                url
              }
            }
          }
          ... on WordPressAcf_two_column_cs {
            id
            sc_lc {
              cs_lc_fc {
                flag_text_cs_lc
                images_cs_lc_repeater {
                  image_cs_lc_ri {
                    alt
                    url
                  }
                }
                large_text_cs_lc
                large_wysiwyg_sc_lc
                quote_cs_lc_text
                small_wysiwyg_cs_lc
                title_cs_lc_text
                author_cs_lc_text
              }
            }
            sc_rc {
              cs_rc_fc {
                images_cs_rc_repeater {
                  image_cs_rc_ri {
                    alt
                    url
                  }
                }
                large_text_cs_rc_i
                large_wysiwyg_cs_rc_i
                small_wysiwyg_cs_rc_i
                quote_cs_rc_text
                title_cs_rc_text
                author_cs_rc_text
                flag_text_cs_rc_i
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        subtitle
      }
    }
    allWordpressWpWork(sort: {order: ASC, fields: menu_order}, filter: {status: {eq: "publish"}, slug: {ne: "do-not-delete"}}) {
      nodes {
        acf {
          case_study {
            client_short_name
            detail_page_hero
            featured_on_homepage
            service_hero_images {
              image {
                alt
                url
                title
              }
            }
            service_preview_description
          }
        }
        menu_order
        link
        title
      }
    }
  }
`
